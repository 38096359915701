/* import __COLOCATED_TEMPLATE__ from './general-settings-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';

export default class EmailEditorComponent extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get customEmail() {
    let customEmail = this.app
      .get('customEmailAddresses')
      .filter(
        (customEmail) =>
          parseInt(customEmail.id, 10) === parseInt(this.localizedEmailContent.replyToId, 10),
      );
    return customEmail.firstObject;
  }

  get isOwnerWithCustomEmailAddressFallback() {
    return (
      this.localizedEmailContent.replyToType === senderTypes.ownerWithCustomEmailAddressFallback
    );
  }

  get isReplyToAdmin() {
    return (
      this.localizedEmailContent.replyToType === senderTypes.admin ||
      this.localizedEmailContent.replyToType === senderTypes.owner
    );
  }

  get isReplyToAdminOwner() {
    return this.localizedEmailContent.replyToType === senderTypes.owner;
  }

  get localizedEmailContent() {
    // the question mark here is needed due to an ember.js framework bug
    // https://github.com/emberjs/ember.js/issues/18969
    return this.args.email?.localizedEmailContents.firstObject;
  }

  get replyToAdmin() {
    let admin = this.app.assignableAdmins.filter(
      (admin) => parseInt(admin.id, 10) === parseInt(this.localizedEmailContent.replyToId, 10),
    );
    return admin.firstObject;
  }
}
