/* import __COLOCATED_TEMPLATE__ from './color-styles.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import AppColor from 'embercom/lib/color';
import { tracked } from '@glimmer/tracking';

export default class ColorStyles extends Component {
  @tracked appColors;

  constructor() {
    super(...arguments);
    let app = this.args.app;
    this.appColors = new AppColor().generate_message_box_colors(app.base_color);
  }
}
