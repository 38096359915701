/* import __COLOCATED_TEMPLATE__ from './prosemirror-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { isEmpty, isPresent } from '@ember/utils';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import foundationStylesTemplate from 'embercom/templates-raw/email-templates/partials/foundations-styles';
import { substituteValues } from 'embercom/lib/outbound/email-template-helper';
import emailStyles from 'embercom/lib/outbound/email-styles-helper';
import EmailComposerConfig from 'embercom/lib/outbound/email-composer-config';
import { premadeTemplates } from 'embercom/models/data/outbound/constants';
import EmailContentHandler from 'embercom/lib/outbound/email-content-handler';

const UNSUBSCRIBE_URL_ATTRIBUTE = encodeURI('{{ unsubscribe_url }}');

export default class ProsemirrorEmailBodyComposer extends Component {
  bodyBlocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service store;

  @tracked emailTemplate;
  @tracked isBlockTemplate = false;

  composerApi;
  contentHandler = new EmailContentHandler(this.store);

  constructor() {
    super(...arguments);
    this.bodyBlocksDoc = new BlocksDocument(this.args.localizedEmailContent?.blocks);
    this.loadEmailTemplate.perform();
  }

  get app() {
    return this.appService.app;
  }

  get avatarURL() {
    let url = this.args.localizedEmailContent?.senderAvatar?.image_urls?.square_50;
    if (isPresent(url)) {
      return `<img src="${url}" height="40" width="40" class="avatar" alt="intercomavatar">`;
    } else {
      return ``;
    }
  }

  get bodyComposerConfig() {
    let config = new EmailComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
      usingBlocksTemplate: this.isBlockTemplate,
    });

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;
    return config;
  }

  @task *loadEmailTemplate() {
    let template = yield this.args.localizedEmailContent?.emailTemplate;
    if (isEmpty(template)) {
      throw new Error(
        `Email template ID: ${this.args.localizedEmailContent?.emailTemplateId}, type: ${this.args.localizedEmailContent?.emailTemplateType} not found.`,
      );
    }
    this.emailTemplate = template;
    this.isBlockTemplate = template.isBlockTemplate;

    this.migratePremadeHtmlToPremadeBlock();
  }

  @action updateEmailTemplateAndBlocks() {
    this.loadEmailTemplate.perform();
    this.bodyBlocksDoc = new BlocksDocument(this.args.localizedEmailContent.blocks);
    this.updateBodyBlocks(this.bodyBlocksDoc);
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedBodyBlocks() {
    return this.args.localizedEmailContent?.blocks.serialize();
  }

  get templateHtml() {
    let html;

    if (isPresent(this.emailTemplate?.template)) {
      html = this.emailTemplate.template;
    } else {
      html = this.emailTemplate?.getBlocksHtml(this.messageLevelStyles);
    }

    if (!html) {
      return '';
    }

    let composerHash = {
      admin_avatar: this.avatarURL,
      admin_name_and_app: this.args.localizedEmailContent?.senderName,
      unsubscribe_link: this.unsubscribeLink,
      unsubscribeLinkClass: this.args.showUnsubscribeLink ? '' : 'hidden',
      foundationStyles: foundationStylesTemplate,
      'widget.address': '<div data-widget="address"></div>',
      'widget.social': '<div data-widget="social"></div>',
      'widget.logo': '<div data-widget="logo"></div>',
    };

    html = substituteValues(html, composerHash);
    if (!this.args.showUnsubscribeLink) {
      html = this.removeUnsubscribeURL(html);
    }
    return html;
  }

  removeUnsubscribeURL(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    let anchors = doc.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      if (anchor.getAttribute('href') === UNSUBSCRIBE_URL_ATTRIBUTE) {
        anchor.parentNode.removeChild(anchor);
      }
    }
    return doc.documentElement.innerHTML;
  }

  get unsubscribeLink() {
    if (this.args.showUnsubscribeLink) {
      if (this.args.isGranularUnsubscribe) {
        return `<a href="#" class="ic-unsubscribe-link">Unsubscribe from this list</a>`;
      }
      return `<a href="#" class="ic-unsubscribe-link">Unsubscribe from our emails</a>`;
    }
    return '';
  }

  get messageLevelStyles() {
    return emailStyles({ template: this.emailTemplate, email: this.args.email });
  }

  @action updateBodyBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.localizedEmailContent.blocks = blockFragments;
  }

  // Migrates existing pre-made html templates to block templates
  // Will be called on update to isEditing
  migratePremadeHtmlToPremadeBlock() {
    if (!this.args.isEditing || !this.app.canUseEmailPremadeTemplateMigration) {
      return;
    }

    if (!['company', 'personal'].includes(this.emailTemplate.id)) {
      return;
    }

    if (this.hasPreviouslyRevertedMigration) {
      return;
    }

    this.contentEditorService.updateAutoSaveEnabled(false);
    this.activeRulesetLink.set('isMigratingEmailTemplate', true);
    let newTemplateId = `${this.emailTemplate.id}_block`;

    let emailContentBlocks = this.args.localizedEmailContent.blocks;
    this.args.localizedEmailContent.blocks = this.contentHandler.wrapWithContentSectionFromTemplate(
      emailContentBlocks.serialize(),
      this.store.peekRecord('email-template', newTemplateId),
    );

    this.args.localizedEmailContent.emailTemplateId = premadeTemplates[newTemplateId];
  }

  get hasPreviouslyRevertedMigration() {
    return this.templateMigrationReverted.includes(this.activeRulesetLink.id);
  }

  get templateMigrationReverted() {
    return this.contentEditorService.ruleset.clientData.templateMigrationReverted;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }
}
