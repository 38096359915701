/* import __COLOCATED_TEMPLATE__ from './manage-processing-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';

interface ManageProcessingBannerSignature {
  Args: {
    app: { id: string | number };
    section: 'ai-agent' | 'ai-copilot' | 'all-inbox-ai' | 'ai-answers';
  };
  Element: HTMLDivElement;
}

export default class ManageProcessingBannerComponent extends Component<ManageProcessingBannerSignature> {
  @service declare router: RouterService;
  @service declare intl: IntlService;

  get text() {
    return {
      'ai-agent': this.intl.t('new-settings.ai-automation.external-ai.banner.ai-agent-disabled'),
      'ai-copilot': this.intl.t(
        'new-settings.ai-automation.external-ai.banner.ai-copilot-disabled',
      ),
      'all-inbox-ai': this.intl.t(
        'new-settings.ai-automation.external-ai.banner.all-inbox-ai-disabled',
      ),
      'ai-answers': this.intl.t(
        'new-settings.ai-automation.external-ai.banner.ai-answers-disabled',
      ),
    }[this.args.section];
  }

  @action manageDataProcessing() {
    this.router.transitionTo('apps.app.settings.ai-automation.external-ai', this.args.app.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalAi::ManageProcessingBanner': typeof ManageProcessingBannerComponent;
    'external-ai/manage-processing-banner': typeof ManageProcessingBannerComponent;
  }
}
