/* import __COLOCATED_TEMPLATE__ from './go-further-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { PRICING_5_X_EARLY_STAGE_SOLUTION_ID } from 'embercom/lib/billing';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export enum CardType {
  SaveWithEarlyStage = 'save-with-early-stage',
  SpeakToASpecialistSmall = 'speak-to-a-specialist-small',
  SpeakToASpecialistLarge = 'speak-to-a-specialist-large',
  HaveQuestions = 'have-questions',
  UpcomingFeatures = 'upcoming-features',
  WorkspaceSetupGuide = 'workspace-setup-guide',
  SuccessStories = 'success-stories',
}

export interface Args {
  cardType: CardType;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLElement;
}

export default class GoFurtherCard extends Component<Signature> {
  @service declare intl: any;
  @service declare router: Router;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get cardType() {
    return this.args.cardType;
  }

  get style() {
    switch (this.cardType) {
      case CardType.SaveWithEarlyStage:
      case CardType.SpeakToASpecialistSmall:
      case CardType.SpeakToASpecialistLarge:
        return 'dark';
      default:
        return 'light';
    }
  }

  get heading() {
    switch (this.cardType) {
      case CardType.SaveWithEarlyStage:
        return this.intl.t('expired-subscription.go-further-card.save-with-early-stage.heading');
      case CardType.SpeakToASpecialistSmall:
        return this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-small.heading',
        );
      case CardType.SpeakToASpecialistLarge:
        return this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-large.heading',
        );
      case CardType.HaveQuestions:
        return this.intl.t('expired-subscription.go-further-card.have-questions.heading');
      case CardType.UpcomingFeatures:
        return this.intl.t('expired-subscription.go-further-card.upcoming-features.heading');
      case CardType.WorkspaceSetupGuide:
        return this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.heading');
      case CardType.SuccessStories:
        return this.intl.t('expired-subscription.go-further-card.success-stories.heading');
    }
  }

  get body() {
    switch (this.cardType) {
      case CardType.SaveWithEarlyStage:
        return this.intl.t('expired-subscription.go-further-card.save-with-early-stage.body');
      case CardType.SpeakToASpecialistSmall:
        return this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-small.body');
      case CardType.SpeakToASpecialistLarge:
        return this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-large.body');
      case CardType.HaveQuestions:
        return this.intl.t('expired-subscription.go-further-card.have-questions.body', {
          htmlSafe: true,
        });
      case CardType.UpcomingFeatures:
        return this.intl.t('expired-subscription.go-further-card.upcoming-features.body');
      case CardType.WorkspaceSetupGuide:
        return this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.body');
      case CardType.SuccessStories:
        return this.intl.t('expired-subscription.go-further-card.success-stories.body');
    }
  }

  get buttonText() {
    switch (this.cardType) {
      case CardType.SaveWithEarlyStage:
        return this.intl.t(
          'expired-subscription.go-further-card.save-with-early-stage.button-text',
        );
      case CardType.SpeakToASpecialistSmall:
        return this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-small.button-text',
        );
      case CardType.SpeakToASpecialistLarge:
        return this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-large.button-text',
        );
      case CardType.HaveQuestions:
        return this.intl.t('expired-subscription.go-further-card.have-questions.button-text');
      case CardType.UpcomingFeatures:
        return this.intl.t('expired-subscription.go-further-card.upcoming-features.button-text');
      case CardType.WorkspaceSetupGuide:
        return this.intl.t(
          'expired-subscription.go-further-card.workspace-setup-guide.button-text',
        );
      case CardType.SuccessStories:
        return this.intl.t('expired-subscription.go-further-card.success-stories.button-text');
    }
  }

  // TODO: ADD ONCLICK ACTIONS FOR SPEAK TO A SPECIALIST AND SWITCHING WORKSPACE
  @action
  onButtonClick() {
    switch (this.buttonAction) {
      case 'transition-to-early-stage-application':
        return this.transitionToEarlyStageApplication();
      case 'open-intercom-widget':
        return showNewMessageInIntercomWidget();
      case 'open-upcoming-features-page':
        return this.openUpcomingFeaturesPage();
      case 'open-workspace-setup-guide-page':
        return this.openWorkspaceSetupGuide();
      case 'open-success-stories-page':
        return this.openSuccessStoriesPage();
    }
  }

  get buttonAction() {
    switch (this.cardType) {
      case CardType.SaveWithEarlyStage:
        return 'transition-to-early-stage-application';
      case CardType.SpeakToASpecialistSmall:
        return 'open-intercom-widget';
      case CardType.SpeakToASpecialistLarge:
        return 'open-intercom-widget';
      case CardType.HaveQuestions:
        return 'open-intercom-widget';
      case CardType.UpcomingFeatures:
        return 'open-upcoming-features-page';
      case CardType.WorkspaceSetupGuide:
        return 'open-workspace-setup-guide-page';
      case CardType.SuccessStories:
        return 'open-success-stories-page';
    }
  }

  transitionToEarlyStageApplication() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'expired_subscription',
      place: 'save_with_early_stage_card',
      object: 'apply_for_early_stage_button',
    });
    this.router.transitionTo(
      'apps.app.teams-checkout.early-stage-application',
      this.appService.app.id,
      {
        queryParams: {
          solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
        },
      },
    );
  }

  openSuccessStoriesPage() {
    safeWindowOpen('https://www.intercom.com/customers#all-stories', '_blank');
  }

  openUpcomingFeaturesPage() {
    safeWindowOpen('https://www.intercom.com/changes/en', '_blank');
  }

  openWorkspaceSetupGuide() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094824-setting-up-your-workspace',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::GoFurther::GoFurtherCard': typeof GoFurtherCard;
    'expired-subscription/go-further/go-further-card': typeof GoFurtherCard;
  }
}
