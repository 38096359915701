/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import {
  UPLOAD_IMAGE_PLACEHOLDER_RECTANGULAR,
  UPLOAD_IMAGE_PLACEHOLDER_CIRCLE,
} from '@intercom/embercom-prosemirror-composer/components/tools/media-inserter/constants';

const BLOCKS_FOR_FONT_FAMILY = [
  'paragraph',
  'heading',
  'subheading',
  'small',
  'button',
  'orderedList',
  'unorderedList',
];
const BLOCKS_FOR_FONT_COLOR = [
  'paragraph',
  'heading',
  'subheading',
  'small',
  'orderedList',
  'unorderedList',
];
const BLOCKS_FOR_BACKGROUND_COLOR = ['button'];
const BLOCKS_FOR_ALIGNMENT = [
  'paragraph',
  'heading',
  'subheading',
  'small',
  'image',
  'button',
  'social',
];
const BLOCKS_FOR_RESIZE = ['image'];
const BLOCKS_FOR_PADDING = ['button', 'table'];
const BLOCKS_FOR_BORDER_RADIUS = ['button', 'table'];
const BLOCKS_FOR_BORDER_COLOR = ['horizontalRule'];

export const DOCUMENT_TYPE = {
  EMAIL: 'email',
  EMAIL_TEMPLATE: 'email-template',
};

export default class Sidebar extends Component {
  @service intercomEventService;
  @service appService;

  colorOptions = DEFAULT_MESSENGER_COLORS;
  blocksForFontColor = BLOCKS_FOR_FONT_COLOR;
  blocksForPadding = BLOCKS_FOR_PADDING;
  blocksForBackgroundColors = BLOCKS_FOR_BACKGROUND_COLOR;
  blocksForBorderRadius = BLOCKS_FOR_BORDER_RADIUS;
  blocksForBorderColor = BLOCKS_FOR_BORDER_COLOR;

  constructor() {
    super(...arguments);
    if (this.isEmailTemplate || this.isEmailWithBlocksTemplate) {
      this.blocksForFontColor = [...BLOCKS_FOR_FONT_COLOR, 'button'];
      this.blocksForBackgroundColors.push('table');
    }
  }

  get _nodeTypeName() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeType.name;
  }

  get showFontFamilySelector() {
    return BLOCKS_FOR_FONT_FAMILY.includes(this._nodeTypeName);
  }

  get showFontColorSelector() {
    return this.blocksForFontColor.includes(this._nodeTypeName);
  }

  get showBackgroundColorSelector() {
    return this.blocksForBackgroundColors.includes(this._nodeTypeName);
  }

  get showBorderColorSelector() {
    return this.blocksForBorderColor.includes(this._nodeTypeName);
  }

  get showAlignment() {
    return BLOCKS_FOR_ALIGNMENT.includes(this._nodeTypeName);
  }

  get showImageSize() {
    return (
      BLOCKS_FOR_RESIZE.includes(this._nodeTypeName) &&
      this.args.composerApi?.composer.config.resizeImages &&
      !this.isImagePlaceHolder &&
      (this.isEmailTemplate || this.isEmailWithBlocksTemplate)
    );
  }

  get isEmailTemplateOrEmailWithBlocksTemplate() {
    return this.isEmailTemplate || this.isEmailWithBlocksTemplate;
  }

  get showBorderRadiusControls() {
    return (
      (this.isEmailTemplate || this.isEmailWithBlocksTemplate) &&
      this.blocksForBorderRadius.includes(this._nodeTypeName)
    );
  }

  get showButtonStyleControl() {
    return (
      (this.isEmailTemplate || this.isEmailWithBlocksTemplate) && this._nodeTypeName === 'button'
    );
  }

  get showPaddingInput() {
    return (
      (this.isEmailTemplate || this.isEmailWithBlocksTemplate) &&
      this.blocksForPadding.includes(this._nodeTypeName)
    );
  }

  get fontFamilies() {
    return [
      {
        items: [
          {
            text: 'Default',
            value: '',
            component: 'email/composer/sidebar/font-family-value',
          },
          {
            text: 'Serif',
            value: 'serif',
            component: 'email/composer/sidebar/font-family-value',
          },
          {
            text: 'Sans Serif',
            value: 'sans-serif',
            component: 'email/composer/sidebar/font-family-value',
          },
          {
            text: 'Monospace',
            value: 'monospace',
            component: 'email/composer/sidebar/font-family-value',
          },
        ],
      },
    ];
  }

  get selectedFontFamily() {
    let selectedValue = this.args.composerApi?.composer.state.selectionAnchorNodeFontFamily || '';
    return this.fontFamilies[0].items.filter((item) => item.value === selectedValue)[0].text;
  }

  get selectedDisplayWidth() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeDisplayWidth;
  }

  get selectedFontColor() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeFontColor;
  }

  get selectedBackgroundColor() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeBackgroundColor;
  }

  get selectedBorderColor() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeBorderColor;
  }

  get selectedAlignment() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeAlignment;
  }

  get selectedTopBottomPadding() {
    let defaultPadding = '12px';
    if (this.selectedBlock?.attrs?.responsive) {
      defaultPadding = '0px';
    }
    return this.args.composerApi?.composer.state.selectionAnchorNodePaddingTop || defaultPadding;
  }

  get selectedSidesPadding() {
    let defaultPadding = '35px';
    if (this.selectedBlock?.attrs?.responsive) {
      defaultPadding = '0px';
    }
    return this.args.composerApi?.composer.state.selectionAnchorNodePaddingLeft || defaultPadding;
  }

  get selectedBlock() {
    return this.args.composerApi?.composer.state.selectionAnchorNode;
  }

  get selectedButtonStyle() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeButtonStyle;
  }

  get selectedBorderRadius() {
    let defaultBorderRadius = '3px';
    if (this.selectedBlock?.attrs?.responsive) {
      defaultBorderRadius = '0px';
    }
    return (
      this.args.composerApi?.composer.state.selectionAnchorNodeBorderRadius || defaultBorderRadius
    );
  }

  get selectedButtonText() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeButtonText;
  }

  get selectedButtonLink() {
    return this.args.composerApi?.composer.state.selectionAnchorNodeButtonLink;
  }

  get currentPosition() {
    return this.args.composerApi?.composer.state.currentAnchorPosition;
  }

  @action
  changeFontFamily(newFontFamily) {
    if (!this.args.composerApi) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'email',
      place: 'composer',
      block_type: this._nodeTypeName,
      font_family: newFontFamily,
    });

    this.args.composerApi.composer.commands.styling.updateFontFamily(newFontFamily);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeFontColor(newColor) {
    if (!this.args.composerApi || newColor === this.selectedFontColor) {
      return;
    }

    this.trackColorAnalyticsEvent('fontColor');
    this.args.composerApi.composer.commands.styling.updateFontColor(newColor);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeBackgroundColor(newColor) {
    if (!this.args.composerApi || newColor === this.selectedBackgroundColor) {
      return;
    }

    this.trackColorAnalyticsEvent('backgroundColor');
    this.args.composerApi.composer.commands.styling.updateBackgroundColor(newColor);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeBorderColor(newColor) {
    if (!this.args.composerApi || newColor === this.selectedBorderColor) {
      return;
    }

    this.trackColorAnalyticsEvent('borderColor');
    this.args.composerApi.composer.commands.updateBorderColor(newColor);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeAlignment() {
    this.args.composerApi.composer.commands.toggleCenter();
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeImageSize(displayWidth) {
    this.args.composerApi.composer.commands.styling.updateImageDisplayWidth(displayWidth);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeTopBottomPadding(newPadding) {
    this.args.composerApi.composer.commands.styling.updateBlockPadding({
      paddingTop: newPadding,
      paddingBottom: newPadding,
    });
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeSidesPadding(newPadding) {
    this.args.composerApi.composer.commands.styling.updateBlockPadding({
      paddingLeft: newPadding,
      paddingRight: newPadding,
    });
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changeButtonStyle() {
    this.args.composerApi.composer.commands.styling.toggleButtonStyle();
  }

  @action
  changeBorderRadius(newBorderRadius) {
    this.args.composerApi.composer.commands.styling.updateBorderRadius(newBorderRadius);
  }

  @action
  changeSelectedButtonText(newButtonText) {
    this.args.composerApi?.composer.commands.updateSelectedButton({
      text: newButtonText,
    });
  }

  @action
  changeSelectedButtonLink(newButtonLink) {
    this.args.composerApi?.composer.commands.updateSelectedButton({
      linkUrl: newButtonLink,
    });
  }

  @action
  hideSectionType() {
    this.args.composerApi?.composer.hideSectionType();
    this.args.composerApi?.composer.commands.collapseSelection();
  }

  trackColorAnalyticsEvent(colorPropertyName) {
    let analyticsEvent = {
      action: 'edited',
      object: 'email',
      place: 'composer',
      block_type: this._nodeTypeName,
    };
    if (colorPropertyName === 'fontColor') {
      this.intercomEventService.trackAnalyticsEvent({
        ...analyticsEvent,
        font_color_changed: true,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        ...analyticsEvent,
        background_color_changed: true,
      });
    }
  }

  get app() {
    return this.appService.app;
  }

  get isImagePlaceHolder() {
    return [UPLOAD_IMAGE_PLACEHOLDER_RECTANGULAR, UPLOAD_IMAGE_PLACEHOLDER_CIRCLE].includes(
      this.args.composerApi?.composer.state.selectionAnchorNode.attrs.src,
    );
  }

  get isEmailTemplate() {
    return this.args.documentType === DOCUMENT_TYPE.EMAIL_TEMPLATE;
  }

  get isEmailWithBlocksTemplate() {
    return this.args.localizedEmailContent?.emailTemplate.isBlockTemplate;
  }
}
