/* import __COLOCATED_TEMPLATE__ from './address.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Address extends Component {
  @service store;
  data = {};

  constructor() {
    super(...arguments);
    let emailTemplateData = this.store.peekAll('email-template-data');
    this.data.address = emailTemplateData.findBy('identifier', 'address')?.value || '';
  }

  get address() {
    let emailTemplateData = this.store.peekAll('email-template-data');
    return emailTemplateData.findBy('identifier', 'address');
  }

  @action
  async saveIfDirty() {
    if (this.data.address !== this.address?.value) {
      if (!this.address) {
        this.store
          .createRecord('email-template-data', {
            identifier: 'address',
            value: this.data.address,
          })
          .save();
      } else if (this.data.address === '') {
        this.address.destroyRecord();
      } else {
        this.address.value = this.data.address;
        this.address.save();
      }
    }
  }
}
