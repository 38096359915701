/* import __COLOCATED_TEMPLATE__ from './general-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class GeneralSettings extends Component {
  @service contentEditorService;

  // https://github.com/intercom/intercom/issues/189901
  // Unassigned was never meant to be a valid option for reply to.
  // We should only allow it to show up in the dropdown for emails who have already previously selected it,
  // so as not to break the editor by having the selected value not appear in the dropdown.
  // this.replyToIsAlreadyUnassigned = this.args.localizedEmailContent.replyToId === '0';
  get replyToIsAlreadyUnassigned() {
    return this.args.localizedEmailContent.replyToId === '0';
  }

  get replyToDropdownVisible() {
    return this.args.localizedEmailContent.isSenderCustomEmail;
  }

  get objectType() {
    return objectTypes.email;
  }

  @action setSenderId(value) {
    this.args.localizedEmailContent.senderId = value;
    this.args.localizedEmailContent.replyToId = value;
  }

  @action setSenderType(value) {
    this.args.localizedEmailContent.senderType = value;
    this.args.localizedEmailContent.replyToType = value;
  }
}
