/* import __COLOCATED_TEMPLATE__ from './logo.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import loadImage from 'load-image';

export default class Logo extends Component {
  @service appService;
  @service store;
  @tracked isResizing = false;
  @tracked url;

  allowedFileTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
  maxWidth = 165;
  maxHeight = 85;
  logoValueBeforeUpload = null;
  originalFileName = null;
  scalingFactor = 1;
  isRetina = false;

  constructor() {
    super(...arguments);
    this.emailTemplateData = this.store.peekAll('email-template-data');
    this.url = this.emailTemplateData.findBy('identifier', 'logo')?.value?.url || '';
  }

  @action
  async uploadLogo(file) {
    this.originalFileName = file.name;
    this.isResizing = true;
    this.logoValueBeforeUpload = this.findOrCreateLogoDatum().value;

    await loadImage(file, (canvas) => this.gotCanvas(canvas), { canvas: true, orientation: true });
  }

  async gotCanvas(canvas) {
    this.setScalingFactorAndIsRetina(canvas);

    if (!this.needsResizing(canvas)) {
      this.uploadResizedImage(canvas);
      return;
    }
    // Show the unprocessed image while we resize
    this.showCanvas(canvas);

    let newDimensions = this.getNewDimensions(canvas);
    await this.resizeCanvas(canvas, newDimensions.x, newDimensions.y);
    this.showCanvas(canvas);
    this.uploadResizedImage(canvas);
  }

  needsResizing(canvas) {
    return canvas.width > this.maxWidth || canvas.height > this.maxHeight;
  }

  setScalingFactorAndIsRetina(canvas) {
    if ((canvas.width > this.maxWidth) * 2 || (canvas.height > this.maxHeight) * 2) {
      this.isRetina = true;
      this.scalingFactor = 2;
    }
  }

  getNewDimensions(canvas) {
    let newWidth;
    let newHeight;
    let aspectRatio = canvas.width / canvas.height;
    if (canvas.width > canvas.height) {
      newWidth = Math.round(this.maxWidth * this.scalingFactor);
      newHeight = Math.round(newWidth / aspectRatio);
    } else {
      newHeight = Math.round(this.maxHeight * this.scalingFactor);
      newWidth = Math.round(newHeight * aspectRatio);
    }
    return {
      x: newWidth,
      y: newHeight,
    };
  }

  async resizeCanvas(canvas, newWidth, newHeight) {
    return new Promise((resolve) => {
      let oldWidth = canvas.width;
      let oldHeight = canvas.height;
      let worker = new window.Worker('/workers/image-resize.js');
      worker.onmessage = function (event) {
        canvas
          .getContext('2d')
          .clearRect(0, 0, Math.max(oldWidth, newWidth), Math.max(oldHeight, newHeight));
        canvas.width = newWidth;
        canvas.height = newHeight;
        canvas.getContext('2d').putImageData(event.data.imageData, 0, 0);
        resolve();
      };
      let oldImageData = canvas.getContext('2d').getImageData(0, 0, oldWidth, oldHeight);
      let newImageData = canvas.getContext('2d').getImageData(0, 0, newWidth, newHeight);
      worker.postMessage({
        oldImageData,
        newImageData,
        oldWidth,
        oldHeight,
        newWidth,
        newHeight,
        lobes: 2,
      });
    });
  }

  showCanvas(canvas) {
    canvas.toBlob((blob) => this.showBlob(blob));
  }

  showBlob(blob) {
    let tempUrl = URL.createObjectURL(blob);
    let logoDatum = this.findOrCreateLogoDatum();
    logoDatum.set('value', { url: tempUrl });
  }

  uploadResizedImage(canvas) {
    this.uploadWidth = canvas.width;
    this.uploadHeigh = canvas.height;
    this.isResizing = false;
    canvas.toBlob((blob) => this.uploadImage(blob));
  }

  async uploadImage(blob) {
    try {
      let file = new File([blob], this.originalFileName);
      let uploader = new EmbercomFileUploader(file, {
        policyUrl: `/apps/${this.appService.app.id}/uploads`,
      });
      await uploader.upload();
      this.uploadSuccess(uploader.policy);
    } catch (error) {
      this.uploadError();
    }
  }

  async uploadSuccess(policy) {
    let onImageReady = () => {
      let logo = this.findOrCreateLogoDatum();
      let value = {
        url: policy.public_url,
        width: this.uploadWidth,
        height: this.uploadHeight,
        isRetina: this.isRetina,
      };
      logo.set('value', value);
      logo.save();
      this.url = policy.public_url;
      this.originalFileName = null;
    };
    await loadImage(policy.public_url, onImageReady);
  }

  uploadError() {
    this.findOrCreateLogoDatum().set('value', this.logoValueBeforeUpload);
  }

  findOrCreateLogoDatum() {
    let logo = this.emailTemplateData.findBy('identifier', 'logo');
    if (!logo) {
      logo = this.store.createRecord('email-template-data', {
        identifier: 'logo',
      });
    }
    return logo;
  }
}
