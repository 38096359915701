/* import __COLOCATED_TEMPLATE__ from './legally-mandated-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LegallyMandatedToggle extends Component {
  @service intercomEventService;
  @tracked showModal = false;

  get isLegallyMandated() {
    return this.args.email.isLegallyMandated;
  }

  @action
  toggleLegallyMandatedValue() {
    this.args.email.isLegallyMandated = !this.isLegallyMandated;
    let localizedEmailContent = this.args.email.localizedEmailContents.firstObject;

    let analyticsParams = {
      action: 'clicked',
      object: 'legally_mandated_toggle',
      is_legally_mandated: this.isLegallyMandated,
      using_custom_domain: localizedEmailContent.isSenderUsingCustomDomain,
    };
    this.intercomEventService.trackAnalyticsEvent(analyticsParams);
  }
}
