/* import __COLOCATED_TEMPLATE__ from './spacer-options.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SpacerOptions extends Component {
  @tracked height;

  constructor() {
    super(...arguments);
    this.height = this.args.composerApi?.composer.state.selectedNodeHeight;
  }

  @action
  onChange() {
    this.args.composerApi.composer.commands.styling.updateNodeHeight(Number(this.height));
  }
}
