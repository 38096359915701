/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import showComposerSidebar from 'embercom/lib/outbound/composer-side-bar-helper';
import { inject as service } from '@ember/service';
import { DOCUMENT_TYPE } from './composer/sidebar';

export default class Sidebar extends Component {
  @service contentEditorService;
  documentType = DOCUMENT_TYPE.EMAIL;

  get showComposerSidebar() {
    let nodeType = this.args.composerApi?.composer.state.selectionAnchorNodeType.name;
    if (nodeType === 'table' && this.isEmailWithBlocksTemplate) {
      return !this.args.composerApi?.composer.state.isSectionSelected;
    }
    return this.args.showComposerSidebar && showComposerSidebar(nodeType);
  }

  get isEmailWithBlocksTemplate() {
    return this.args.localizedEmailContent?.emailTemplate.isBlockTemplate;
  }
}
