/* import __COLOCATED_TEMPLATE__ from './html-mode-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import splitHtml from '@intercom/embercom-composer/lib/split-html';
import { emailTemplateTypes } from 'embercom/models/data/outbound/constants';
import { inject as service } from '@ember/service';
const DEFAULT_HTML_CONTENT = `
  <p>Hi there</p>
  {{! -- Remember to add an '{{unsubscribe_link}}' to your HTML if "Show unsubscribe link" is enabled --}}
`;

export default class EmailHtmlModeSwitcherComponent extends Component {
  @service store;
  @service intercomConfirmService;

  @action switchToHtmlMode() {
    this.switch();
  }

  async switch() {
    let options = {
      title: 'Switch to HTML editor?',
      body: 'This action can’t be undone and will override existing message content.',
      confirmButtonText: 'Switch to HTML editor',
    };

    let confirmed = await this.intercomConfirmService.confirm(options);

    if (confirmed) {
      let emailTemplate = this.store.peekRecord('email-template', 'html-block-template');
      let splitTemplate = splitHtml(emailTemplate.get('template'));
      let rawBlocks = [
        {
          type: 'html',
          content: DEFAULT_HTML_CONTENT,
          header: splitTemplate.header,
          footer: splitTemplate.footer,
        },
      ];

      let blocks = rawBlocks.map((block) => createFragmentFromBlock(this.store, block));
      this.args.localizedEmailContent.set('blocks', blocks);
      this.args.localizedEmailContent.emailTemplateType = emailTemplateTypes.htmlMode;
      this.args.localizedEmailContent.emailTemplateId = -1;
    }
  }
}
