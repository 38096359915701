/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class EmailHtmlComposerEditorComponent extends Component {
  @service attributeService;
  @service contentEditorService;
  @service store;

  get selectedEvent() {
    return this.contentEditorService.selectedEvent;
  }

  get selectedEvents() {
    if (!this.selectedEvent) {
      return [];
    }

    return [this.selectedEvent];
  }

  get email() {
    return this.args.localizedEmailContent.email;
  }

  get showUnsubscribeLink() {
    return this.email.get('showUnsubscribeLink');
  }
}
