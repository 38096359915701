/* import __COLOCATED_TEMPLATE__ from './content-link-text.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: Element;
}

const ContentLinkText = templateOnlyComponent<Signature>();
export default ContentLinkText;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentLinkText': typeof ContentLinkText;
  }
}
