/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';

interface Signature {
  Args: {
    source: SyncSourceWrapper;
    publishedContentCount: number;
  };
}

const Status = templateOnlyComponent<Signature>();
export default Status;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ExternalContent::Status': typeof Status;
  }
}
