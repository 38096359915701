/* import __COLOCATED_TEMPLATE__ from './ember-data-benchmark.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type BenchmarkAdmin from 'embercom/models/benchmark-admin';
import type Store from '@ember-data/store';
import { timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';

interface Args {
  adminJson: Array<any>;
  iterations: string;
}

export default class EmberDataBenchmark extends Component<Args> {
  @tracked iterations = Number(this.args.iterations);
  @tracked isRunning = false;

  @service declare store: Store;

  @dropTask *runBenchmarkTask() {
    this.isRunning = true;

    yield timeout(10);
    this.duration = 0;
    this.microsecondDuration = 0;

    let start = performance.now();

    for (let i = 0; i < this.iterations; i++) {
      this.store.unloadAll('benchmark-admin');

      this.admins = this.args.adminJson.map((adminJson) => {
        this.store.push(this.store.normalize('benchmark-admin', adminJson));
        return this.store.peekRecord('benchmark-admin', adminJson.id);
      });
    }

    let end = performance.now();

    let totalDuration = end - start;

    this.duration = totalDuration / this.iterations;

    this.microsecondDuration =
      (1000 * totalDuration) / (this.iterations * this.args.adminJson.length);

    this.isRunning = false;
  }

  @action runBenchmark() {
    taskFor(this.runBenchmarkTask).perform();
  }

  @tracked duration = 0;
  @tracked microsecondDuration = 0;

  get roundedDuration() {
    return this.duration.toFixed(2);
  }

  get roundedMicrosecondDuration() {
    return this.microsecondDuration.toFixed(2);
  }

  @tracked admins: Array<BenchmarkAdmin> = [];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EmberDataBenchmark: typeof EmberDataBenchmark;
  }
}
