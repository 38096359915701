/* import __COLOCATED_TEMPLATE__ from './social.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Social extends Component {
  @service store;
  data = {};

  constructor() {
    super(...arguments);
    let emailTemplateData = this.store.peekAll('email-template-data');
    this.data.facebook = emailTemplateData.findBy('identifier', 'facebook')?.value || '';
    this.data.twitter = emailTemplateData.findBy('identifier', 'twitter')?.value || '';
  }

  get facebook() {
    let emailTemplateData = this.store.peekAll('email-template-data');
    return emailTemplateData.findBy('identifier', 'facebook');
  }

  get twitter() {
    let emailTemplateData = this.store.peekAll('email-template-data');
    return emailTemplateData.findBy('identifier', 'twitter');
  }

  @action
  async saveIfDirty() {
    if (this.data.facebook !== this.facebook?.value) {
      if (this.data.facebook && !this.facebook) {
        this.store
          .createRecord('email-template-data', {
            identifier: 'facebook',
            value: this.data.facebook,
          })
          .save();
      } else if (this.data.facebook === '' && this.facebook?.value) {
        this.facebook.destroyRecord();
      } else if (this.facebook?.value) {
        this.facebook.value = this.data.facebook;
        this.facebook.save();
      }
    }

    if (this.data.twitter !== this.twitter?.value) {
      if (this.data.twitter && !this.twitter) {
        this.store
          .createRecord('email-template-data', {
            identifier: 'twitter',
            value: this.data.twitter,
          })
          .save();
      } else if (this.data.twitter === '' && this.twitter?.value) {
        this.twitter.destroyRecord();
      } else if (this.twitter?.value) {
        this.twitter.value = this.data.twitter;
        this.twitter.save();
      }
    }
  }
}
