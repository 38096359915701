/* import __COLOCATED_TEMPLATE__ from './add-content-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface AddContentButtonArgs {
  onClick: () => void;
  contentSource: string;
  disabled?: boolean;
}

interface AddContentButtonSignature {
  Args: AddContentButtonArgs;
}

const AddContentButton = templateOnlyComponent<AddContentButtonSignature>();

export default AddContentButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::AddContentButton': typeof AddContentButton;
  }
}
