/* import __COLOCATED_TEMPLATE__ from './content-count.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface ContentCountArgs {
  enabledCount: number;
  allCount: number;
}

const ContentCount = templateOnlyComponent<ContentCountArgs>();

export default ContentCount;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentCount': typeof ContentCount;
  }
}
