/* import __COLOCATED_TEMPLATE__ from './template-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  defaultContentBlock,
  getTemplate,
  getTemplateTitle,
  isSwitchingFromBlockTemplateToLegacy,
} from 'embercom/lib/outbound/email-template-helper';
import {
  emailTemplateTypes,
  templateSwitcherOptions,
} from 'embercom/models/data/outbound/constants';

import EmailContentHandler from 'embercom/lib/outbound/email-content-handler';

export default class EmailSidebarTemplateSwitcherComponent extends Component {
  @service appService;
  @service store;
  @service router;
  @service intercomEventService;
  @service contentEditorService;
  @tracked items = [];
  @tracked selectedTemplate;
  @tracked displayModal = false;
  @tracked displayConfirmation = false;
  @tracked displayTemplateSwitcherModal = false;

  contentHandler = new EmailContentHandler(this.store);

  get app() {
    return this.appService.app;
  }

  get selectedTemplateName() {
    let template = getTemplateTitle(
      this.args.localizedEmailContent.emailTemplateType,
      this.args.localizedEmailContent.emailTemplateId,
      this.store,
    );
    return template.name;
  }

  isSwitchingToBlocksTemplate(template) {
    return template.isBlockTemplate;
  }

  @action
  async selectTemplateFromPreview(identifier, switcherValue) {
    await this.selectTemplateFromModal(identifier, switcherValue);
    this.displayTemplateSwitcherModal = false;
    this.disableComposerInteractivity(this.displayTemplateSwitcherModal);
  }

  @action
  setDisplayTemplateSwitcherModal(display) {
    this.displayTemplateSwitcherModal = display;
    this.disableComposerInteractivity(this.displayTemplateSwitcherModal);
  }

  @action
  async selectTemplateFromModal(identifier, switcherValue) {
    let [templateType, templateId] = identifier.split(':');
    let template = await getTemplate(templateType, templateId, this.store);

    if (this.isSwitchingToBlocksTemplate(template)) {
      this.handleBlocksTemplateSwitching(switcherValue, template);
    } else if (
      isSwitchingFromBlockTemplateToLegacy(this.args.localizedEmailContent.emailTemplate, template)
    ) {
      this.handleBlockTemplateToLegacySwitching(templateType, templateId);
    } else {
      this.trackAnalyticsEvent({
        template_changed: 'html',
        template_changed_to: templateId,
      });
    }

    this.args.localizedEmailContent.emailTemplateType = Number(templateType);
    this.args.localizedEmailContent.emailTemplateId = Number(templateId);
  }

  handleBlocksTemplateSwitching(switcherValue, template) {
    if (switcherValue === templateSwitcherOptions.templateContent) {
      this.args.localizedEmailContent.blocks = this.contentHandler.createFragments(
        template.contentBlocks,
      );
      this.trackAnalyticsEvent({
        template_changed: 'visual',
        template_changed_to: template.id,
        keep_content: false,
      });
      return;
    }

    this.args.localizedEmailContent.blocks = this.contentHandler.getBlocksForTemplate(
      this.args.localizedEmailContent.blocks,
      template,
    );

    this.trackAnalyticsEvent({
      template_changed: 'visual',
      template_changed_to: template.id,
      keep_content: true,
    });
  }

  handleBlockTemplateToLegacySwitching(templateType) {
    this.args.localizedEmailContent.blocks = this.contentHandler.createFragments(
      defaultContentBlock(this.app),
    );

    let templateTypeString;
    if (Number(templateType) === emailTemplateTypes.custom) {
      templateTypeString = 'html';
    } else if (Number(templateType) === emailTemplateTypes.premade) {
      templateTypeString = 'premade';
    }

    this.trackAnalyticsEvent({
      template_changed: 'visual',
      template_changed_to: templateTypeString,
      keep_content: true,
    });
  }

  trackAnalyticsEvent(templateSwitchAnalytics) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'email',
      place: 'composer',
      ruleset_id: this.contentEditorService.ruleset.id,
      ...templateSwitchAnalytics,
    });
  }

  disableComposerInteractivity(modalDisplayed) {
    modalDisplayed
      ? this.args.composerApi?.composer.disableInteractivity()
      : this.args.composerApi?.composer.enableInteractivity();
  }
}
