/* import __COLOCATED_TEMPLATE__ from './deliverability-advice-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class DeliverabilityAdviceBanner extends Component {
  @service contentEditorService;
  @service store;

  get domain() {
    let email = this.args.localizedEmailContent.senderEmail;
    return sanitizeHtml(email.split('@', 2)[1]);
  }

  get fullyAuthenticatedCustomDomainRequired() {
    return (
      this.contentEditorService.ruleset.isMatchBehaviorMulti ||
      this.args.localizedEmailContent.get('email.sendToUnsubscribed')
    );
  }

  get hasCustomAssetsDomain() {
    return this.customAssetsDomains.length > 0;
  }

  get customAssetsDomains() {
    return this.store.peekAll('settings/custom-email-assets-domain');
  }

  get isCustomAssetsDomainValid() {
    if (!this.hasCustomAssetsDomain) {
      return true;
    }
    return this.customAssetsDomains.firstObject.validRecordExists;
  }
}
