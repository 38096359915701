/* import __COLOCATED_TEMPLATE__ from './template-switcher-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { templateSwitcherOptions } from 'embercom/models/data/outbound/constants';

export default class TemplateSwitcherModal extends Component {
  @tracked selectedTemplate;
  @tracked templateSwitcherObject = {
    switcherValue: templateSwitcherOptions.emailContent,
  };

  @action
  callOnTemplateSelected() {
    this.args.onTemplateSelected(this.selectedTemplate, this.templateSwitcherObject.switcherValue);
  }

  @action
  updateSelectedTemplate(newTemplateIdentifier) {
    this.selectedTemplate = newTemplateIdentifier;
  }
}
