/* import __COLOCATED_TEMPLATE__ from './run-status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { runTask, pollTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';

import type ContentImportService from 'embercom/services/content-import-service';
import type Store from '@ember-data/store';
import type ContentImportRun from 'embercom/models/content-service/content-import-run';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { tracked } from '@glimmer/tracking';

interface Args {
  contentImportRun: ContentImportRun;
  contentImportSource: ContentImportSource;
  isLocationOH?: boolean;
  hideUsedByFinStamp?: boolean;
  completionCallback?: Function;
}

export default class RunStatus extends Component<Args> {
  @service declare store: Store;
  @service declare contentImportService: ContentImportService;

  @tracked isLocationOH = false;

  importRunCompleted = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.contentImportRun) {
      pollTask(this, 'updateContentImportRun');
    }
    if (args.isLocationOH) {
      this.isLocationOH = args.isLocationOH;
    }
  }

  async updateContentImportRun(next: any) {
    await this.args.contentImportRun.reload();
    if (this.args.contentImportRun.isInProgress) {
      this.importRunCompleted = false;
      runTask(this, next, ENV.APP._5000MS);
    } else if (!this.importRunCompleted && this.args.contentImportRun.isFullyIngested) {
      this.importRunCompleted = true;
      this.args.completionCallback?.();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::RunStatus': typeof RunStatus;
  }
}
