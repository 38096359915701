/* import __COLOCATED_TEMPLATE__ from './widget-editors.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

interface Args {
  iframe: HTMLIFrameElement;
  isReadOnly: boolean;
  templateId: string;
}

export default class WidgetEditors extends Component<Args> {
  templateWidgets: { [template: string]: string[] } = {
    announcement: ['address', 'social', 'logo'],
    company: ['address', 'logo'],
    company_block: ['address', 'logo'],
  };

  get widgets(): string[] {
    return this.templateWidgets[this.args.templateId];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Email::Composer::WidgetEditors': typeof WidgetEditors;
    'email/composer/widget-editors': typeof WidgetEditors;
  }
}
