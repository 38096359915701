/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  title: string;
  description: string;
  learnMoreLink: string;
  testAttribute: string;
}

interface Signature {
  Args: Args;
}

const DiscoveryBanner = templateOnlyComponent<Signature>();
export default DiscoveryBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::DiscoveryBanner': typeof DiscoveryBanner;
    'fin-ai-agent/knowledge/discovery-banner': typeof DiscoveryBanner;
  }
}
