/* import __COLOCATED_TEMPLATE__ from './failed.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    source: string;
    isLocationOH?: boolean;
  };
}

const Failed = templateOnlyComponent<Signature>();
export default Failed;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::Status::Failed': typeof Failed;
  }
}
