/* import __COLOCATED_TEMPLATE__ from './message-format-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { isBlank } from '@ember/utils';

const FONT_STYLES = {
  bold: 'bold',
  italic: 'italic',
  normal: 'normal',
};

export default class MessageFormatSettings extends Component {
  @service store;
  @service intercomEventService;
  @tracked supportsContentBackgroundColor;
  @tracked supportsBodyBackgroundColor;
  colorOptions = DEFAULT_MESSENGER_COLORS;

  constructor() {
    super(...arguments);
  }

  get email() {
    return this.args.email;
  }

  get template() {
    return this.args.localizedEmailContent?.emailTemplate;
  }

  get isBold() {
    if (isBlank(this.email.linkFontWeight)) {
      return this.template?.css?.templateLinkFontWeight === FONT_STYLES.bold;
    }
    return this.email.linkFontWeight === FONT_STYLES.bold;
  }

  get isItalic() {
    if (isBlank(this.email.linkFontStyle)) {
      return this.template?.css?.templateLinkFontStyle === FONT_STYLES.italic;
    }
    return this.email.linkFontStyle === FONT_STYLES.italic;
  }

  get selectedFontColor() {
    return this.email.linkFontColor || this.template?.css?.templateLinkFontColor || '';
  }

  get selectedContentBackgroundColor() {
    return this.email.contentBackgroundColor || '';
  }

  get selectedBodyBackgroundColor() {
    return this.email.bodyBackgroundColor || '';
  }

  @action
  async updateColorPickers() {
    let emailTemplate = await this.args.localizedEmailContent?.emailTemplate;
    let templateHtml = emailTemplate?.template;
    this.supportsBodyBackgroundColor =
      templateHtml?.includes('intercom-template-body') || emailTemplate.isBlockTemplate;
    this.supportsContentBackgroundColor =
      templateHtml?.includes('intercom-template-content') ||
      emailTemplate.isBlockTemplateWithContainer;
    if (!this.supportsBodyBackgroundColor) {
      this.email.bodyBackgroundColor = '';
    }
    if (!this.supportsContentBackgroundColor) {
      this.email.contentBackgroundColor = '';
    }
  }

  @action
  changeContentBackgroundColor(newContentBackgroundColor) {
    this.email.contentBackgroundColor = newContentBackgroundColor;
    this.trackAnalyticsEvent({ background_color_changed: 'content' });
  }

  @action
  changeBodyBackgroundColor(newBodyBackgroundColor) {
    this.email.bodyBackgroundColor = newBodyBackgroundColor;
    this.trackAnalyticsEvent({ background_color_changed: 'body' });
  }

  @action
  changeColor(newColor) {
    this.email.linkFontColor = newColor;
    this.trackAnalyticsEvent({ links_font_color_changed: true });
  }

  @action
  toggleBoldStyle() {
    this.email.linkFontWeight = this.isBold ? FONT_STYLES.normal : FONT_STYLES.bold;
    this.trackAnalyticsEvent({ links_font_weight: this.email.linkFontWeight });
  }

  @action
  toggleItalicStyle() {
    this.email.linkFontStyle = this.isItalic ? FONT_STYLES.normal : FONT_STYLES.italic;
    this.trackAnalyticsEvent({ links_font_style: this.email.linkFontStyle });
  }

  trackAnalyticsEvent(messageStyleAnalytics) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'email',
      place: 'composer',
      ...messageStyleAnalytics,
    });
  }
}
