/* import __COLOCATED_TEMPLATE__ from './image-size-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ImageSizeInput extends Component {
  @tracked size;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  @action
  initialize() {
    if (this.args.size) {
      this.size = this.args.size;
    } else {
      this.size = 100; // will change after a prosemirror update
    }
  }

  @action
  onChange() {
    this.args.onChange(Number(this.size));
  }
}
