/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { PREVIEW_DEVICE_OPTIONS } from '../../lib/outbound/constants';

export default class EmailEditorComponent extends Component {
  DEVICE_WEB = 'web';
  DEVICE_MOBILE = 'mobile';

  previewSwitcherDeviceOptions = PREVIEW_DEVICE_OPTIONS;

  @service appService;
  @service intercomEventService;
  @tracked composerApi;
  @tracked showComposerSidebar;
  @tracked currentDevice = this.previewSwitcherDeviceOptions[0].device;

  get app() {
    return this.appService.app;
  }

  get deviceTypeIsMobile() {
    return this.currentDevice === this.previewSwitcherDeviceOptions[1].device;
  }

  get localizedEmailContent() {
    // the question mark here is needed due to an ember.js framework bug
    // https://github.com/emberjs/ember.js/issues/18969
    return this.args.email?.localizedEmailContents.firstObject;
  }

  get usingUnauthenticatedCustomEmailAddress() {
    let fromAddress = this.localizedEmailContent.customFromAddress;
    if (fromAddress) {
      return fromAddress.status === 'Unverified domain';
    }
    return false;
  }

  get isGranularUnsubscribe() {
    return isPresent(this.args.email?.subscriptionTypeId);
  }

  @action
  updateCurrentPreviewDevice(device) {
    this.currentDevice = device;
  }

  @action
  trackClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_custom_sender_address',
      context: 'learn_more_banner_link',
    });
  }

  @action
  toggleMobilePreview() {
    if (this.deviceTypeIsMobile) {
      this.updateCurrentPreviewDevice(this.DEVICE_WEB);
    } else {
      this.updateCurrentPreviewDevice(this.DEVICE_MOBILE);
    }
  }
}
