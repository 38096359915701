/* import __COLOCATED_TEMPLATE__ from './editable-text-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import { action } from '@ember/object';
import $ from 'jquery';
import { equal, empty, not } from '@ember/object/computed';
import Component from '@ember/component';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import { ternaryToProperty, trim } from '@intercom/pulse/lib/computed-properties';

export default Component.extend(InboundActions, {
  displayMode: true,
  originalText: '',
  placeholderText: '',
  text: '',
  emptyString: '',
  truncateText: false,
  editableTextBlank: equal('editableText', undefined),
  originalTextEmpty: empty('originalText'),
  textToDisplay: ternaryToProperty('editableTextBlank', 'text', 'editableText'),
  textToDisplayIsBlank: empty('textToDisplay'),
  textToDisplayIsNotBlank: not('textToDisplayIsBlank'),
  editableTextForTrimmer: ternaryToProperty('editableTextBlank', 'emptyString', 'editableText'),
  trimmedOriginalText: trim('originalText'),
  trimmedEditableText: trim('editableTextForTrimmer'),

  setTexts(text) {
    this.set('editableText', text);
    this.set('originalText', text);
  },

  exitEditMode: action(function () {
    this.set('displayMode', true);
  }),

  enterEditMode: action(function () {
    if (this.originalTextEmpty) {
      this.setTexts(this.text || '');
    }
    this.set('displayMode', false);
    schedule('afterRender', this, function () {
      if (this.textareaMode) {
        $('textarea', this.element).focus();
      } else {
        $('input', this.element).focus();
      }
    });
  }),

  saveChanges: action(function () {
    this.exitEditMode();
    let editedText = this.trimmedEditableText;
    if (editedText !== this.trimmedOriginalText) {
      this.saveAction?.(editedText, this.trimmedOriginalText === '');
    }
    let texts = editedText || this.trimmedOriginalText;
    this.setTexts(texts);
  }),
});
