/* import __COLOCATED_TEMPLATE__ from './social-item-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SocialItemInput extends Component {
  @tracked url = '';

  constructor() {
    super(...arguments);
    this.initialize();
  }

  @action
  initialize() {
    if (this.args.socialItem.url) {
      this.url = this.args.socialItem.url;
    }
  }

  @action
  onChange() {
    this.args.onChange(this.args.index, this.url);
  }
}
