/* import __COLOCATED_TEMPLATE__ from './image-size.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ImageSize extends Component {
  @tracked displayHeight;
  @tracked displayWidth;
  @tracked imageSrc;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  get maxWidth() {
    return this.selectedImageParentWidth;
  }

  get minWidth() {
    return parseInt(this.minHeight * this.selectedImageRatio, 10);
  }

  get maxHeight() {
    return parseInt(this.selectedImageParentWidth / this.selectedImageRatio, 10);
  }

  get minHeight() {
    return Math.max(1, parseInt(1 / this.selectedImageRatio, 10));
  }

  get initialImageWidth() {
    if (this.args.selectedDisplayWidth) {
      return this.args.selectedDisplayWidth;
    }
    if (this.selectedImageAttrs?.width < this.selectedImageParentWidth) {
      return this.selectedImageAttrs.width;
    }
    return this.selectedImageParentWidth;
  }

  get selectedImageParentWidth() {
    return this.args.composerApi.composer.state.selectedNodeParentWidth;
  }

  get selectedImageRatio() {
    let imageAttrs = this.selectedImageAttrs;
    if (!imageAttrs) {
      return null;
    }
    let { width, height } = imageAttrs;
    return width / height;
  }

  get selectedImageAttrs() {
    let imageAttrs = this.args.composerApi.composer.state.selectionAnchorNode?.attrs;
    if (!imageAttrs) {
      return null;
    }
    return imageAttrs;
  }

  @action
  initialize() {
    if (this.selectedImageAttrs?.src !== this.imageSrc) {
      this.imageSrc = this.selectedImageAttrs?.src;
      this.displayWidth = this.initialImageWidth;
      this.displayHeight = parseInt(this.displayWidth / this.selectedImageRatio, 10);
    }
  }

  @action
  onWidthChange(width) {
    if (width < this.minWidth || width > this.maxWidth) {
      this.displayWidth = this.displayWidth;
      return;
    }
    let height = parseInt(width / this.selectedImageRatio, 10);
    this.args.onChange(Number(width));
    this.displayWidth = width;
    this.displayHeight = height;
  }

  @action
  onHeightChange(height) {
    if (height < this.minHeight || height > this.maxHeight) {
      this.displayHeight = this.displayHeight;
      return;
    }
    let width = parseInt(height * this.selectedImageRatio, 10);
    this.args.onChange(Number(width));
    this.displayHeight = height;
    this.displayWidth = width;
  }
}
