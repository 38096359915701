/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  iframe: HTMLIFrameElement;
  isReadOnly: boolean;
  onTooltipHide: () => void;
  type: string;
}

type VirtualElement = {
  getBoundingClientRect: () => DOMRect;
  contextElement?: Element;
};

export default class WidgetEditor extends Component<Args> {
  @tracked widgetElement?: HTMLElement | null;
  @tracked hoveredElement?: VirtualElement | null;
  @tracked isEditing = false;
  @tracked showModal = false;
  @tracked showTooltip = false;

  @action
  setupWidget(): void {
    this.widgetElement = this.args.iframe.contentDocument?.querySelector(
      `[data-widget="${this.args.type}"]`,
    ) as HTMLElement;
  }

  get generateGetBoundingClientRect(): DOMRect {
    let rect = this.widgetElement?.getBoundingClientRect();
    let iframeRect = this.args.iframe.getBoundingClientRect();
    if (!rect) {
      return new DOMRect();
    }
    return new DOMRect(rect?.x + iframeRect.x, rect?.y + iframeRect.y, rect?.width, rect?.height);
  }

  @action
  setHoveredElement(): void {
    this.hoveredElement = {
      getBoundingClientRect: () => this.generateGetBoundingClientRect,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Email::Composer::WidgetEditors::Editor': typeof WidgetEditor;
    'email/composer/widget-editors/editor': typeof WidgetEditor;
  }
}
