/* import __COLOCATED_TEMPLATE__ from './embercom-revision-banner.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';

export default class EmbercomRevisionBanner extends Component {
  get isValidRevision() {
    let currentlyLoadedRevision = Ember.libraries._registry
      .find((library) => library.name === 'Embercom')
      .version.replace('0.0.0+', '');
    return this.args.embercomRevision.startsWith(currentlyLoadedRevision);
  }
  get isExperimentalBuild() {
    return this.args.embercomRevision.includes('-experimental');
  }
  get embercomRevisionProcessed() {
    return this.args.embercomRevision.replace('-experimental', '');
  }
  get bannerType() {
    return this.isValidRevision ? 'warning' : 'error';
  }
}
