/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';

interface Args {
  type: 'primary' | 'secondary' | 'tertiary';
  label: string;
  onClick?: (e: MouseEvent) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Button extends Component<Signature> {
  get isPrimary() {
    return this.args.type === 'primary';
  }

  get isSecondary() {
    return this.args.type === 'secondary';
  }

  get isTertiary() {
    return this.args.type === 'tertiary';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::Button': typeof Button;
    'expired-subscription/button': typeof Button;
  }
}
