/* import __COLOCATED_TEMPLATE__ from './emoji-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import storage from 'embercom/vendor/intercom/storage';
import intermoji from '@intercom/intermoji';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

const titleItem = function (title) {
  return EmberObject.create({
    isTitle: true,
    title,
    width: 283,
    height: 40,
  });
};

const spacerItem = function () {
  return EmberObject.create({
    isTitle: true,
    width: 283,
    height: 40,
  });
};

const emojiItem = function (emoji) {
  return EmberObject.create({
    width: 44,
    height: 40,
    emoji,
  });
};

export default Component.extend({
  classNames: ['flex-auto', 'flex', 'flex-col', 'emoji-component-wrapper'],
  emoji: intermoji,
  emojiSize: 16,
  spaced: false,
  maxUserEmoji: 10,
  emojiImageClass: 'emoji__image',
  defaultEmojis: ['thumbs_up', '-1', 'sob', 'confused', 'neutral_face', 'blush', 'heart_eyes'],
  emojiFilter: '',
  hasFilter: notEmpty('emojiFilter'),
  filteredEmojis: computed('emojiFilter', function () {
    let regexp = new RegExp(this.emojiFilter, 'i');
    return intermoji.allEmoticonsIdentifierList
      .filter((identifier) => regexp.test(identifier))
      .reduce((acc, identifier) => {
        let unicodeIcon = this.emoji.unicodeFromIdentifier(identifier);
        if (this.emoji.isEmojiBaseUnicode(unicodeIcon)) {
          return [...acc, this._buildEmojiItem({ identifier })];
        } else {
          return acc;
        }
      }, []);
  }),

  init() {
    this._super(...arguments);
    let userEmojis = storage.get('userEmojis') || [];
    let userEmojiIdentifiersUnsupported = userEmojis.some(
      (userEmoji) => !this.emoji.isSupportedIdentifier(userEmoji),
    );

    if (userEmojiIdentifiersUnsupported) {
      storage.set('userEmojis', userEmojis);
      this.set('userEmojis', []);
    } else {
      this.set('userEmojis', userEmojis);
    }
  },

  selectEmoji() {},

  hasNativeSupport: computed(function () {
    if (this.forceTwemojis) {
      return false;
    }
    return this.emoji.hasNativeSupport(document);
  }),

  _addUserEmoji(emojiIdentifier) {
    if (this.defaultEmojis.includes(emojiIdentifier)) {
      return;
    }
    let userEmojis = this.userEmojis;
    if (userEmojis.length > this.maxUserEmoji) {
      userEmojis.popObject();
    }
    if (userEmojis.includes(emojiIdentifier)) {
      userEmojis.removeObject(emojiIdentifier);
    }
    userEmojis.unshiftObject(emojiIdentifier);
    storage.set('userEmojis', userEmojis);
  },

  lastUsedEmojis: computed('userEmojis.[]', function () {
    let identifiers = this.userEmojis.concat(this.defaultEmojis);

    return identifiers.map((identifier) => this._buildEmojiItem({ identifier }));
  }),

  groups: computed(function () {
    let groups = A();
    let representatives = this.emoji.getGroupRepresentatives();

    this.emoji.prettyEmoticonsUnicodeGroups().forEach((unicodeIcons, index) => {
      let emojis = A();
      unicodeIcons.forEach((unicodeIcon) => {
        if (this.emoji.isEmojiBaseUnicode(unicodeIcon)) {
          emojis.pushObject(this._buildEmojiItem({ unicodeIcon }));
        }
      });

      groups.pushObject({
        name: representatives[index][1],
        emojis,
      });
    });

    return groups;
  }),

  allEmojiItems: computed('lastUsedEmojis.[]', 'groups.{name,emojis.[]}', function () {
    let items = [];
    let uniqueIdentifiers = new Set();

    items.push(titleItem('Frequently used'));

    items.pushObjects(this.lastUsedEmojis);

    // Remove duplicates based on identifier
    items = items.filter((item) => {
      if (item.emoji?.identifier && !uniqueIdentifiers.has(item.emoji.identifier)) {
        uniqueIdentifiers.add(item.emoji.identifier);
        return true;
      }
      return !item.emoji?.identifier;
    });

    this.groups.forEach((group) => {
      items.push(titleItem(group.name));
      items.pushObjects(group.emojis);
    });

    items.push(spacerItem());
    return items;
  }),
  collectionItems: ternaryToProperty('hasFilter', 'filteredEmojis', 'allEmojiItems'),

  _buildEmojiItem({ unicodeIcon, identifier }) {
    if (!identifier && !unicodeIcon) {
      throw new Error('You must provide one of `identifier` or `unicodeIcon`');
    }
    if (!identifier) {
      identifier = this.emoji.identifierFromUnicode(unicodeIcon);
    } else if (!unicodeIcon) {
      unicodeIcon = this.emoji.unicodeFromIdentifier(identifier);
    }
    return emojiItem({
      display: this._getDisplayableEmoji(unicodeIcon),
      identifier,
      title: `:${identifier}:`,
    });
  },

  _getDisplayableEmoji(unicodeIcon) {
    let display = unicodeIcon;
    if (!this.hasNativeSupport) {
      display = this.emoji.fallbackImage(this.emojiSize, display, this.emojiImageClass);
    }
    return display;
  },

  actions: {
    selected(emojiIdentifier) {
      this._addUserEmoji(emojiIdentifier);
      this.selectEmoji(emojiIdentifier);
    },
  },
});
