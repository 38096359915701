/* import __COLOCATED_TEMPLATE__ from './layout-options.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class LayoutOptions extends Component {
  @tracked stacked;

  constructor() {
    super(...arguments);
    if (typeof this.args.composerApi?.composer.state.tableStackedStatus === 'boolean') {
      this.stacked = this.args.composerApi?.composer.state.tableStackedStatus;
    } else {
      this.stacked = true;
    }
  }

  @action
  toggleStacked() {
    this.stacked = !this.stacked;
    this.args.composerApi.composer.commands.toggleTableStacking();
    this.args.composerApi.composer.commands.focus();
  }
}
