/* import __COLOCATED_TEMPLATE__ from './progress.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import type ContentImportRun from 'embercom/models/content-service/content-import-run';

interface Signature {
  Args: {
    contentImportRun: ContentImportRun;
    isLocationOH?: boolean;
  };
}

const Progress = templateOnlyComponent<Signature>();
export default Progress;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::Status::Progress': typeof Progress;
  }
}
