/* import __COLOCATED_TEMPLATE__ from './template-migration-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  emailTemplateTypes,
  premadeTemplateIDMapping,
  premadeTemplates,
} from 'embercom/models/data/outbound/constants';
import EmailContentHandler from 'embercom/lib/outbound/email-content-handler';

export default class TemplateMigrationBanner extends Component {
  @service contentEditorService;
  @service store;
  @service intercomEventService;

  @tracked displayChangeWillBeLostModal = false;

  contentHandler = new EmailContentHandler(this.store);

  get localizedEmailContent() {
    return this.contentEditorService.activeObject?.localizedEmailContents.firstObject;
  }

  get emailTemplateId() {
    return Number(this.localizedEmailContent?.emailTemplateId);
  }

  get clientData() {
    return this.contentEditorService?.ruleset?.clientData;
  }

  get usingLegacyPersonalOrCompanyTemplate() {
    return [premadeTemplates.company, premadeTemplates.personal].includes(this.emailTemplateId);
  }

  get usingNewPersonalOrCompanyTemplate() {
    return [premadeTemplates.company_block, premadeTemplates.personal_block].includes(
      this.emailTemplateId,
    );
  }

  get templateMigrationReverted() {
    return this.clientData?.templateMigrationReverted;
  }

  get templateMigrationAccepted() {
    return this.clientData?.templateMigrationAccepted;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  get isActiveRulesetLinkMigrating() {
    return this.activeRulesetLink?.isMigratingEmailTemplate;
  }

  get hasRevertedTemplateMigration() {
    return this.templateMigrationReverted?.includes(this.activeRulesetLink.id);
  }

  get hasNotAcceptedTemplateMigration() {
    return !this.templateMigrationAccepted?.includes(this.activeRulesetLink.id);
  }

  @action
  async acceptMigration() {
    this.changeTemplate(emailTemplateTypes.premade, this.emailTemplateId);
    this.contentEditorService.updateAutoSaveEnabled(true);
    await this.contentEditorService.saveRuleset.perform();
  }

  @action
  async revertMigration() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'email',
      action: 'template_revert_migration',
      ruleset: this.activeRulesetLink.parentRulesetId,
      ruleset_link: this.activeRulesetLink.id,
    });
    this.localizedEmailContent.rollbackAttributes();
    this.clientData.templateMigrationReverted.pushObject(this.activeRulesetLink.id);
    this.activeRulesetLink.set('isMigratingEmailTemplate', false);
    this.contentEditorService.updateAutoSaveEnabled(true);
    this.displayChangeWillBeLostModal = false;
  }

  @action
  update() {
    this.contentEditorService.updateAutoSaveEnabled(false);
    this.activeRulesetLink.set('isMigratingEmailTemplate', true);
    let templateId =
      this.emailTemplateId === premadeTemplates.personal
        ? premadeTemplates.personal_block
        : premadeTemplates.company_block;
    this.changeTemplate(emailTemplateTypes.premade, templateId);

    let template = this.store.peekRecord('email-template', premadeTemplateIDMapping[templateId]);
    this.localizedEmailContent.blocks = this.contentHandler.wrapWithContentSectionFromTemplate(
      this.localizedEmailContent.blocks.serialize(),
      template,
    );
  }

  changeTemplate(templateType, templateId) {
    this.localizedEmailContent.emailTemplateType = Number(templateType);
    this.localizedEmailContent.emailTemplateId = Number(templateId);
  }
}
