/* import __COLOCATED_TEMPLATE__ from './expired-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { ExpirationReason } from './header/header-card';
import { CardType } from './go-further/go-further-card';

export interface Args {
  expirationReason: ExpirationReason | undefined;
  companySize: CompanySize;
  isBillingAdmin: boolean;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export enum CompanySize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export default class ExpiredSubscription extends Component<Signature> {
  get goFurtherCardTypes() {
    return [this.firstCard, this.secondCard, this.thirdCard].filter(
      (cardType): cardType is CardType => !!cardType,
    );
  }

  private get firstCard() {
    switch (this.args.expirationReason) {
      case ExpirationReason.SubscriptionCancelled:
      case ExpirationReason.NewWorkspace:
        switch (this.args.companySize) {
          case CompanySize.Large:
            return CardType.SpeakToASpecialistLarge;
          default:
            return CardType.SpeakToASpecialistSmall;
        }
      case ExpirationReason.NonPaymentSalesLed:
        return undefined;
      case ExpirationReason.NonPaymentSelfServe:
        return CardType.HaveQuestions;
      default:
        switch (this.args.companySize) {
          case CompanySize.Large:
            return CardType.SpeakToASpecialistLarge;
          case CompanySize.Medium:
            return CardType.SpeakToASpecialistSmall;
          default:
            if (this.args.isBillingAdmin) {
              return CardType.SaveWithEarlyStage;
            }
            return CardType.SpeakToASpecialistSmall;
        }
    }
  }

  private get secondCard() {
    switch (this.args.expirationReason) {
      case ExpirationReason.NewWorkspace:
        return undefined;
      default:
        return CardType.UpcomingFeatures;
    }
  }

  private get thirdCard() {
    switch (this.args.expirationReason) {
      case ExpirationReason.NewWorkspace:
        return CardType.WorkspaceSetupGuide;
      default:
        return CardType.SuccessStories;
    }
  }

  get headerCardDataTest() {
    return `${this.args.expirationReason}-${this.args.isBillingAdmin ? 'with-billing-admin' : 'without-billing-admin'}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::ExpiredSubscription': typeof ExpiredSubscription;
    'expired-subscription/expired-subscription': typeof ExpiredSubscription;
  }
}
