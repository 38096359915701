/* import __COLOCATED_TEMPLATE__ from './social-items-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SOCIAL_CONSTANTS } from '@intercom/interblocks.ts';

export default class SocialItemsModal extends Component {
  @tracked selectedSocialItems = [];
  @tracked availableSocialItemsToSelect = [];
  @tracked highlightIndex;
  @tracked filterSocialItemsInput;

  constructor() {
    super(...arguments);
  }

  @action
  openModal() {
    this.selectedSocialItems = [];
    this.updateAvailableSocialItemsToSelect();
  }

  @action
  updateAvailableSocialItemsToSelect() {
    this.availableSocialItemsToSelect = [];
    this.availableSocialItems().forEach((socialItem) => {
      if (!this.socialAlreadySelected(socialItem)) {
        this.availableSocialItemsToSelect.pushObject(socialItem);
      }
    });

    this.availableSocialItemsToSelect.sort(this.getSortFunctionByProvider());
  }

  getSortFunctionByProvider() {
    return (a, b) => {
      if (a.provider < b.provider) {
        return -1;
      }
      return a.provider > b.provider ? 0 : 1;
    };
  }

  socialAlreadySelected(socialItem) {
    return (
      this.selectedSocialItems.filter((item) => item.provider === socialItem.provider).length > 0
    );
  }

  @action
  addSocialItem(socialItem) {
    this.selectedSocialItems.pushObject(socialItem);
    this.filterSocialItemsInput = '';
    this.updateAvailableSocialItemsToSelect();
  }

  @action
  removeSocialItem(socialItem) {
    this.selectedSocialItems.removeObject(socialItem);
    this.updateAvailableSocialItemsToSelect();
  }

  @action
  addSelectedSocialItems() {
    this.args.addSocialItems(this.selectedSocialItems);
  }

  @action
  filterSocialItemsToSelect() {
    this.updateAvailableSocialItemsToSelect();
    if (this.filterSocialItemsInput.trim().length > 0) {
      this.availableSocialItemsToSelect = this.availableSocialItemsToSelect.filter((socialItem) =>
        socialItem.provider.toLowerCase().startsWith(this.filterSocialItemsInput.toLowerCase()),
      );
    }
  }

  availableSocialItems() {
    let availableSocialItems = [];
    let availableProviderNames = this.args.activeSocialItems.map(
      (socialItem) => socialItem.provider,
    );
    let socialProviders = Object.keys(SOCIAL_CONSTANTS);

    socialProviders.forEach((providerName) => {
      if (!availableProviderNames.includes(providerName)) {
        availableSocialItems.push({ provider: providerName, url: '' });
      }
    });
    return availableSocialItems;
  }
}
