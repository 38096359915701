/* import __COLOCATED_TEMPLATE__ from './base-header-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  heading: string;
  body: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: any;
}

const BaseHeaderCard = templateOnlyComponent<Signature>();
export default BaseHeaderCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::Header::BaseHeaderCard': typeof BaseHeaderCard;
    'expired-subscription/header/base-header-card': typeof BaseHeaderCard;
  }
}
