/* import __COLOCATED_TEMPLATE__ from './prosemirror-subject.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import IfElseStatementBlockEditorComposerConfig from 'embercom/objects/composer/config/email-subject-if-else-statement-block-editor';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph', 'ifElseStatement'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];

  constructor({ app, intl, placeholder, resolver }) {
    super();

    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };

    this.inserters = {
      ifElseStatementInserter: {
        name: 'composer/if-else-statement-block-editor',
        options: {
          composerConfig: new IfElseStatementBlockEditorComposerConfig(app, true),
          requireNonEmptyBlocks: true,
          editorTitle: intl.t('outbound.email.composer.prosemirror-subject.dynamic-subject-line'),
        },
      },
    };

    this.tools = [
      ...this.tools,
      {
        name: 'media-inserter',
        options: {
          singleOptionInserterMode: true,
          singleOptionInserterTooltipTitle: intl.t(
            'outbound.email.composer.prosemirror-subject.make-subject-line-dynamic',
          ),
        },
      },
      { name: 'if-else-statement-editor' },
    ];
  }
}

export default class ProsemirrorEmailSubjectComposer extends Component {
  subjectBlocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service store;
  @service intl;

  constructor() {
    super(...arguments);
    this.subjectBlocksDoc = new BlocksDocument(this.serializedSubjectBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get subjectComposerConfig() {
    return new ComposerConfig({
      app: this.app,
      intl: this.intl,
      placeholder: 'Your subject...',
      resolver: this.resolver,
    });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedSubjectBlocks() {
    if (this.shouldRenderSubjectFromRulesetSnapshot) {
      return [
        {
          type: 'paragraph',
          text: this.args.localizedEmailContent.subject,
        },
      ];
    }
    return this.args.localizedEmailContent?.subjectBlocks.serialize();
  }

  get shouldRenderSubjectFromRulesetSnapshot() {
    return (
      this.contentEditorService.isViewingVersionSnapshot &&
      !this.args.localizedEmailContent?.subjectBlocksHasContent &&
      this.args.localizedEmailContent?.subjectIsPresent
    );
  }

  @action updateSubjectBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.localizedEmailContent.subjectBlocks = blockFragments;
  }
}
