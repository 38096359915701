/* import __COLOCATED_TEMPLATE__ from './social-items.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SocialItems extends Component {
  @tracked showSocialItemsModal = false;

  get socialItems() {
    return this.args.composerApi?.composer.state.selectionAnchorNode?.attrs.items;
  }

  @action
  deleteSocialItem(arrayId) {
    this.args.composerApi?.composer.commands.deleteSocialItem(arrayId);
  }

  @action
  addSocialItems(socialItems) {
    this.args.composerApi?.composer.commands.insertSocialItems(socialItems);
  }

  @action
  updateSocialItemUrl(index, url) {
    this.args.composerApi?.composer.commands.updateSocialItemUrl(index, url);
  }

  @action
  openModal() {
    this.args.composerApi?.composer.disableInteractivity();
    this.showSocialItemsModal = true;
  }

  @action
  closeModal() {
    this.args.composerApi?.composer.enableInteractivity();
    this.showSocialItemsModal = false;
  }
}
