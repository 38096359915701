/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { empty } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  objectFilter: '',
  objectFilterKey: 'name',
  objectWrapperHeight: computed('objectsToDisplay.length', 'objectHeight', function () {
    let height = this.get('objectsToDisplay.length') * this.objectHeight;
    return `height: ${height}px`;
  }),
  objectsAreUnfiltered: empty('objectFilter'),
  objectsToDisplay: ternaryToProperty('objectsAreUnfiltered', 'objects', 'filteredObjects'),
  filteredObjects: computed('objectFilter', 'objects.[]', 'objectFilterKey', function () {
    let lowerCaseObjectFilter = this.objectFilter.toLowerCase();
    let objects = this.objects;
    let objectFilterKey = this.objectFilterKey;
    return objects.filter((object) =>
      object.get(objectFilterKey).toLowerCase().includes(lowerCaseObjectFilter),
    );
  }),
});
