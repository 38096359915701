/* import __COLOCATED_TEMPLATE__ from './unsubscribe.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class UnsubscribeComponent extends Component {
  @service appService;
  @service contentEditorService;
  @service intercomEventService;

  get app() {
    return this.appService.app;
  }

  get localizedEmailContent() {
    this.args.email.localizedEmailContents.firstObject;
  }

  get allUnsubscribeLinksAreDisabled() {
    if (!this.app.hideAllUnsubscribeLinks) {
      return false;
    }

    return this.app.trustedForUnsubscribeLinks;
  }

  get showUnsubscribeLink() {
    if (this.allUnsubscribeLinksAreDisabled) {
      return false;
    }

    return this.args.email.showUnsubscribeLink;
  }

  @action toggleUnsubscribe() {
    this.args.email.showUnsubscribeLink = !this.showUnsubscribeLink;
    let localizedEmailContent = this.args.email.localizedEmailContents.firstObject;

    let analyticsParams = {
      action: 'clicked',
      object: 'unsubscribe_link_toggle',
      show_unsubscribe_link: this.showUnsubscribeLink,
      using_custom_domain: localizedEmailContent.isSenderUsingCustomDomain,
    };
    this.intercomEventService.trackAnalyticsEvent(analyticsParams);
  }
}
