/* import __COLOCATED_TEMPLATE__ from './header-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';
import { type Router } from '@ember/routing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { tracked } from '@glimmer/tracking';

export enum ExpirationReason {
  FreeTrialEnded = 'free-trial-ended',
  OneDollarTrialEnded = 'one-dollar-trial-ended',
  SubscriptionCancelled = 'subscription-cancelled',
  NonPaymentSelfServe = 'non-payment-self-serve',
  NonPaymentSalesLed = 'non-payment-sales-led',
  NewWorkspace = 'new-workspace',
}

type ButtonAction =
  | 'open-one-dollar-trial-modal'
  | 'open-cardless-conversion-modal'
  | 'transition-to-billing-settings'
  | 'open-intercom-widget'
  | 'toggle-alert-billing-admin-modal';

export interface Args {
  expirationReason: ExpirationReason | undefined;
  isBillingAdmin: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: any;
}

export default class HeaderCard extends Component<Signature> {
  @service declare intl: any;
  @service declare appService: any;
  @service declare router: Router;
  @service declare cardlessConversionModalService: CardlessConversionModalService;

  @tracked shouldShowAlertBillingAdminModal = false;

  get expirationReason() {
    return this.args.expirationReason;
  }

  get heading() {
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return this.intl.t('expired-subscription.header.free-trial-ended.heading');
      case ExpirationReason.OneDollarTrialEnded:
        return this.intl.t('expired-subscription.header.one-dollar-trial-ended.heading');
      case ExpirationReason.SubscriptionCancelled:
        return this.intl.t('expired-subscription.header.subscription-cancelled.heading');
      case ExpirationReason.NonPaymentSelfServe:
        return this.intl.t('expired-subscription.header.non-payment-self-serve.heading');
      case ExpirationReason.NonPaymentSalesLed:
        return this.intl.t('expired-subscription.header.non-payment-sales-led.heading');
      case ExpirationReason.NewWorkspace:
        return this.intl.t('expired-subscription.header.new-workspace.heading');
      default:
        return this.intl.t('expired-subscription.header.default.heading');
    }
  }

  get body() {
    return this.args.isBillingAdmin ? this.bodyBillingAdmin : this.bodyNonBillingAdmin;
  }

  get bodyBillingAdmin() {
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return this.intl.t('expired-subscription.header.free-trial-ended.body.with-billing');
      case ExpirationReason.OneDollarTrialEnded:
        return this.intl.t('expired-subscription.header.one-dollar-trial-ended.body.with-billing');
      case ExpirationReason.SubscriptionCancelled:
        return this.intl.t('expired-subscription.header.subscription-cancelled.body.with-billing');
      case ExpirationReason.NonPaymentSelfServe:
        return this.intl.t('expired-subscription.header.non-payment-self-serve.body.with-billing');
      case ExpirationReason.NonPaymentSalesLed:
        return this.intl.t('expired-subscription.header.non-payment-sales-led.body.with-billing', {
          htmlSafe: true,
        });
      case ExpirationReason.NewWorkspace:
        return this.intl.t('expired-subscription.header.new-workspace.body.with-billing');
      default:
        return this.intl.t('expired-subscription.header.default.body.with-billing');
    }
  }

  get bodyNonBillingAdmin() {
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return this.intl.t('expired-subscription.header.free-trial-ended.body.without-billing');
      case ExpirationReason.OneDollarTrialEnded:
        return this.intl.t(
          'expired-subscription.header.one-dollar-trial-ended.body.without-billing',
        );
      case ExpirationReason.SubscriptionCancelled:
        return this.intl.t(
          'expired-subscription.header.subscription-cancelled.body.without-billing',
        );
      case ExpirationReason.NonPaymentSelfServe:
        return this.intl.t(
          'expired-subscription.header.non-payment-self-serve.body.without-billing',
        );
      case ExpirationReason.NonPaymentSalesLed:
        return this.intl.t(
          'expired-subscription.header.non-payment-sales-led.body.without-billing',
          {
            htmlSafe: true,
          },
        );
      case ExpirationReason.NewWorkspace:
        return this.intl.t('expired-subscription.header.new-workspace.body.without-billing');
      default:
        return this.intl.t('expired-subscription.header.default.body.without-billing');
    }
  }

  get primaryButtonLabel() {
    return this.args.isBillingAdmin
      ? this.primaryButtonLabelBillingAdmin
      : this.primaryButtonLabelNonBillingAdmin;
  }

  private get primaryButtonLabelBillingAdmin() {
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return this.intl.t('expired-subscription.header.free-trial-ended.button.primary');
      case ExpirationReason.OneDollarTrialEnded:
        return this.intl.t('expired-subscription.header.one-dollar-trial-ended.button.primary');
      case ExpirationReason.SubscriptionCancelled:
        return this.intl.t('expired-subscription.header.subscription-cancelled.button.primary');
      case ExpirationReason.NonPaymentSelfServe:
        return this.intl.t('expired-subscription.header.non-payment-self-serve.button.primary');
      case ExpirationReason.NonPaymentSalesLed:
        return this.intl.t('expired-subscription.header.non-payment-sales-led.button.primary');
      case ExpirationReason.NewWorkspace:
        return this.intl.t('expired-subscription.header.new-workspace.button.primary');
      default:
        return this.intl.t('expired-subscription.header.default.button.primary');
    }
  }

  private get primaryButtonLabelNonBillingAdmin() {
    switch (this.expirationReason) {
      case ExpirationReason.NonPaymentSalesLed:
        return this.intl.t('expired-subscription.chat-to-us');
      default:
        return this.intl.t('expired-subscription.alert-billing-admin');
    }
  }

  get secondaryButtonLabel() {
    if (!this.args.isBillingAdmin) {
      return undefined;
    }
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return this.intl.t('expired-subscription.header.free-trial-ended.button.secondary');
      default:
        return undefined;
    }
  }

  @action
  onPrimaryButtonClick() {
    this.doButtonAction(this.primaryButtonAction);
  }

  @action
  onSecondaryButtonClick() {
    if (this.secondaryButtonAction) {
      this.doButtonAction(this.secondaryButtonAction);
    }
  }

  get primaryButtonAction() {
    if (!this.args.isBillingAdmin) {
      switch (this.expirationReason) {
        case ExpirationReason.NonPaymentSalesLed:
          return 'open-intercom-widget';
        default:
          return 'toggle-alert-billing-admin-modal';
      }
    }
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return 'open-one-dollar-trial-modal';
      case ExpirationReason.NonPaymentSelfServe:
        return 'transition-to-billing-settings';
      case ExpirationReason.NonPaymentSalesLed:
        return 'open-intercom-widget';
      case ExpirationReason.OneDollarTrialEnded:
      case ExpirationReason.SubscriptionCancelled:
      case ExpirationReason.NewWorkspace:
      default:
        return 'open-cardless-conversion-modal';
    }
  }

  get secondaryButtonAction() {
    if (!this.args.isBillingAdmin) {
      return;
    }
    switch (this.expirationReason) {
      case ExpirationReason.FreeTrialEnded:
        return 'open-cardless-conversion-modal';
      default:
        return;
    }
  }

  @action
  private doButtonAction(buttonAction: ButtonAction) {
    switch (buttonAction) {
      case 'open-cardless-conversion-modal':
        return this.openCardlessConversionModal();
      case 'transition-to-billing-settings':
        return this.transitionToBillingSettings();
      case 'open-intercom-widget':
        return showNewMessageInIntercomWidget();
      case 'toggle-alert-billing-admin-modal':
        return this.toggleAlertBillingAdminModal();
    }
  }

  @action
  openCardlessConversionModal() {
    this.cardlessConversionModalService.toggle();
  }

  @action
  transitionToBillingSettings() {
    this.router.transitionTo('apps.app.settings.workspace.billing.settings');
  }

  @action
  toggleAlertBillingAdminModal() {
    this.shouldShowAlertBillingAdminModal = !this.shouldShowAlertBillingAdminModal;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::Header::HeaderCard': typeof HeaderCard;
    'expired-subscription/header/header-card': typeof HeaderCard;
  }
}
