/* import __COLOCATED_TEMPLATE__ from './undo-redo.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class UndoRedo extends Component {
  @action
  undo() {
    return this.args.composerApi.composer.commands.undo();
  }

  @action
  redo() {
    return this.args.composerApi.composer.commands.redo();
  }

  get undoDisabled() {
    return this.args.composerApi?.composer.state.undoDisabled;
  }

  get redoDisabled() {
    return this.args.composerApi?.composer.state.redoDisabled;
  }
}
