/* import __COLOCATED_TEMPLATE__ from './editor-with-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { isNone } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import splitHtml from '@intercom/embercom-composer/lib/split-html';
import { encodeUriAttributes } from '@intercom/embercom-composer/lib/attribute-encoding';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { tracked } from '@glimmer/tracking';

export default class EmailHtmlComposerEditorWithPreviewComponent extends Component {
  @service store;
  @tracked html;

  constructor() {
    super(...arguments);
    this.extractHtml();
  }

  extractHtml() {
    let htmlBlock = this.args.localizedEmailContent.blocks.findBy('type', 'html');
    if (isNone(htmlBlock)) {
      throw new Error(`The HTML composer must receive a HTML block`);
    }
    this.html = htmlBlock.combinedHtml;
  }

  @action updateHtml(value) {
    encodeUriAttributes(value);
    let splitTemplate = splitHtml(value);
    let htmlBlock = {
      type: 'html',
      content: splitTemplate.content,
      footer: splitTemplate.footer,
      header: splitTemplate.header,
    };
    this.args.localizedEmailContent.set('blocks', [createFragmentFromBlock(this.store, htmlBlock)]);
    this.extractHtml();
  }
}
