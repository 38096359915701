/* import __COLOCATED_TEMPLATE__ from './expanding-textarea-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import $ from 'jquery';
import Component from '@ember/component';
import { addEventListener } from 'ember-lifeline';

const ENTER = 13;

export default Component.extend({
  classNames: ['f__expanding-textarea-wrapper', 'o__fit'],

  defaultTextareaClasses:
    'm__conversation-expanding-textarea f__expanding-textarea-field o__fit f__text',

  minHeight: 70,
  maxHeight: 200,
  maxLength: '65535',
  inConversationCard: false,
  useNoteStyle: false,

  didInsertElement() {
    this._super(...arguments);
    this.set('inConversationCard', $(this.element).parents('.conversation__card').length !== 0);
    this.styleTextarea();
    this.stylePre(false);
    this.set('textareaDimensions', this._getCurrentDimensions());
    this.set('textareaElement', $('textarea', this.element)[0]);
    addEventListener(this, window, 'resize', () => this.setMaxHeight());
  },

  willDestroyElement() {
    this.set('textareaElement', null);
    this._super(...arguments);
  },

  setMaxHeight() {
    let scrollHeight = $('.conversation__view__stream-wrapper').height();
    let maxHeight = `${(scrollHeight / 100) * 25}px`;
    this.set('maxHeight', maxHeight);
    this.stylePre();
    this.styleTextarea();
  },

  textareaClasses: computed('useNoteStyle', function () {
    let defaultClasses = this.defaultTextareaClasses;
    if (this.useNoteStyle) {
      return `${defaultClasses} o__note`;
    } else {
      return defaultClasses;
    }
  }),

  preCursorContent: computed('textareaContent', 'textareaElement', function () {
    let properties = this.getProperties('textareaContent', 'textareaElement');
    if (!properties.textareaContent || !properties.textareaElement) {
      return '';
    }
    let cursorPosition = properties.textareaElement.selectionStart;
    return properties.textareaContent.substr(0, cursorPosition);
  }),

  postCursorContent: computed('textareaContent', 'textareaElement', function () {
    let properties = this.getProperties('textareaContent', 'textareaElement');
    if (!properties.textareaContent || !properties.textareaElement) {
      return '';
    }
    let cursorPosition = properties.textareaElement.selectionStart;
    return properties.textareaContent.substr(cursorPosition);
  }),

  focusIn() {
    this.set(
      'minHeight',
      parseInt($('textarea', this.element).css('paddingTop'), 10) +
        parseInt($('textarea', this.element).css('paddingBottom'), 10) +
        parseInt($('textarea', this.element).css('lineHeight'), 10) * 3,
    );
    this.stylePre(true);
    if (this.onFocusIn) {
      this.onFocusIn();
    }
  },

  focusOut() {
    if ($.trim($('textarea', this.element).val()) === '') {
      this.stylePre(false);
    }
    this._changeOrInput();
    if (this.onFocusOut) {
      this.onFocusOut();
    }
  },

  input() {
    this._changeOrInput();
  },

  change() {
    this._changeOrInput();
  },

  keyDown(e) {
    if (e.keyCode === ENTER && this.onEnter) {
      this.onEnter();
    }
  },

  _changeOrInput() {
    this.set('textareaDimensions', this._getCurrentDimensions());
    this.set('cursorPosition', this._getCursorPosition());
  },

  _getCurrentDimensions() {
    let $textarea = $('textarea', this.element);
    return {
      width: $textarea.width(),
      height: $textarea.height(),
      scrollbarWidth: this.getScrollbarWidth($textarea),
      scrollbarHeight: this.getScrollbarHeight($textarea),
    };
  },

  _getCursorPosition() {
    let textarea = $('.js_cursor_position', this.element)[0];
    let top = textarea ? textarea.offsetTop : 0;
    let left = textarea ? textarea.offsetLeft : 0;
    return {
      top,
      left,
    };
  },

  getScrollbarWidth($textarea) {
    return $textarea[0].offsetWidth - $textarea[0].clientWidth;
  },

  getScrollbarHeight($textarea) {
    return $textarea[0].offsetHeight - $textarea[0].clientHeight;
  },

  styleTextarea() {
    let textarea = $('textarea', this.element);
    textarea.css({
      minHeight: this.minHeight,
      maxHeight: this.maxHeight,
    });
  },

  stylePre(focused) {
    let textarea = $('textarea', this.element);
    let pre = $('pre', this.element);
    if (typeof focused !== 'undefined') {
      if (focused) {
        pre.addClass('focused');
      } else {
        pre.removeClass('focused');
      }
    }
    pre.css({
      padding: textarea.css('padding'),
      fontSize: textarea.css('fontSize'),
      lineHeight: textarea.css('lineHeight'),
      font: textarea.css('font'),
      border: textarea.css('border'),
      minHeight: this.minHeight,
      overflow: 'hidden',
      boxSizing: textarea.css('box-sizing'),
      maxHeight: this.maxHeight,
      marginBottom: textarea.css('marginBottom'),
    });
  },
});
