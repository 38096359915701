/* import __COLOCATED_TEMPLATE__ from './file-picker.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';
import { generateFakePng } from '@intercom/embercom-prosemirror-composer/lib/helpers/image';
import ENV from 'embercom/config/environment';

export default class FilePicker extends Component {
  @ref('inputElement') fileInput;

  get allowedTypeList() {
    return this.args.allowedFileTypes.join(',');
  }

  isAllowedType(file) {
    if (this.args.allowedFileTypes.includes('*')) {
      return true;
    }
    return this.args.allowedFileTypes.includes(file.type);
  }

  get isTest() {
    return ENV.environment === 'test';
  }

  @action
  open() {
    this.fileInput.value = '';

    if (this.isTest) {
      // It is impossible to programmatically trigger file selection so we fake it in test
      let file = new File([generateFakePng()], 'Fake png', { type: 'image/png' });
      if (this.isAllowedType(file)) {
        this.args.onFileChosen(file);
      }
    } else {
      this.fileInput.click();
    }
  }

  @action
  onChange(e) {
    let target = e.target;
    if (target?.files) {
      let file = target.files[0];
      if (this.isAllowedType(file)) {
        this.args.onFileChosen(file);
      }
    }
  }
}
