/* import __COLOCATED_TEMPLATE__ from './content-source-status.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: {
    status: string | undefined;
  };
}

export default class ContentSourceStatus extends Component<Signature> {
  @service declare intl: IntlService;

  get text() {
    if (!this.args.status) {
      return;
    }

    return (
      {
        enabled: this.intl.t('fin-ai-agent.knowledge.content-sources.status.enabled'),
        not_enabled: this.intl.t('fin-ai-agent.knowledge.content-sources.status.not_enabled'),
        syncing: this.intl.t('fin-ai-agent.knowledge.content-sources.status.syncing'),
        in_progress: this.intl.t('fin-ai-agent.knowledge.content-sources.status.in_progress'),
        error: this.intl.t('fin-ai-agent.knowledge.content-sources.status.error'),
      }[this.args.status] || this.intl.t('fin-ai-agent.knowledge.content-sources.status.enabled')
    );
  }

  get stampColor(): any {
    if (!this.args.status) {
      return;
    }

    return (
      {
        enabled: 'green',
        syncing: 'yellow-light',
        in_progress: 'yellow-light',
        error: 'red',
        not_enabled: 'gray',
      }[this.args.status] || 'green'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentSourceStatus': typeof ContentSourceStatus;
  }
}
