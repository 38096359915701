/* import __COLOCATED_TEMPLATE__ from './content-source-row.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

export interface ContentSourceRowArgs {
  icon: $TSFixMe;
  onClick: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

export interface ContentSourceRowSignature {
  Args: ContentSourceRowArgs;
  Blocks: {
    name: [];
    statusLabel: [];
    dropdownActions: [];
  };
  Element: Element;
}

const ContentSourceRow = templateOnlyComponent<ContentSourceRowSignature>();
export default ContentSourceRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentSourceRow': typeof ContentSourceRow;
  }
}
